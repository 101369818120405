<template>
  <div v-show="isAllowed" @click="downloadAgreement" class="btn btn-primary--inverse">
    <img src="@/assets/img/icons/document.svg" />
    <span>{{ buttonName }}</span>
  </div>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';

export default {
  name: 'sell-download-fkp-btn',
  props: {
    allowedActions: {
      required: true,
      validator: value => {
        if (value !== null) {
          return value.constructor.name === 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'DOWNLOADPDF',
      actionType: 'SELL_TRANSACTION',
    };
  },
  methods: {
    async downloadAgreement() {
      await this.$store.dispatch('sellTransaction/downloadFkp', this.applicationUuid);
    },
  },
};
</script>

<style scoped></style>
